<template>
  <div :class="'checkbox ' + checkboxClass">
    <input
      :type="inputType"
      :value="value"
      :name="name"
      :id="id"
      @change="updateValue($event.target)"
      :checked="isChecked"
    />
    <span class="checkbox-handle"></span>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    id: {
      type: String,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    checkboxClass: {
      type: String,
      default: "checkbox",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    inputType: {
      type: String,
      default: "checkbox",
    },
    name: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit("updateInput", value);

      //Função para a checkbox agir como radio button
      if (value.parentElement.classList.contains("radio-checkbox")) {
        document
          .querySelectorAll("[name='" + value.name + "']")
          .forEach((checkbox) => {
            if (checkbox != value) {
              checkbox.checked = false;
              this.$emit("updateInput", checkbox);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Checkbox";
</style>
