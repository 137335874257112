<template>
  <div :class="'row checkbox-container checkbox-' + containerClass">
    <Checkbox
      :value="value"
      :checkboxClass="checkboxClass"
      :inputType="inputType"
      :name="name"
      :id="id"
      :isChecked="isChecked"
      @updateInput="updateInput($event)"
    />
    <label v-if="label" :for="id" class="sm-text">{{ label }}</label>
    <slot></slot>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox/Checkbox.vue";

export default {
  name: "CheckboxContainer",
  components: {
    Checkbox,
  },
  props: {
    id: {
      type: String,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    checkboxClass: {
      type: String,
      default: "checkbox",
    },
    label: {
      type: String,
      default: "",
    },
    containerClass: {
      type: String,
      default: "left",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    inputType: {
      type: String,
      default: "checkbox",
    },
    name: {
      type: String,
      default: "",
    },
    price: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    updateInput(value) {
      this.$emit("updateInput", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Checkbox";
</style>
